@font-face {
    font-family: Gilroy;
    src: url('../fonts/gilroy-heavyitalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: Gilroy;
    src: url('../fonts/gilroy-extrabolditalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: Gilroy;
    src: url('../fonts/gilroy-blackitalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: Gilroy;
    src: url('../fonts/gilroy-bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: Gilroy;
    src: url('../fonts/gilroy-heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: Gilroy;
    src: url('../fonts/gilroy-black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: Gilroy;
    src: url('../fonts/gilroy-bolditalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: Gilroy;
    src: url('../fonts/gilroy-lightitalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: Gilroy;
    src: url('../fonts/gilroy-medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: Gilroy;
    src: url('../fonts/gilroy-semibolditalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: Gilroy;
    src: url('../fonts/gilroy-ultralight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: Gilroy;
    src: url('../fonts/gilroy-regularitalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: Gilroy;
    src: url('../fonts/gilroy-thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: Gilroy;
    src: url('../fonts/gilroy-ultralightitalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: Gilroy;
    src: url('../fonts/gilroy-regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: Gilroy;
    src: url('../fonts/gilroy-mediumitalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: Gilroy;
    src: url('../fonts/gilroy-semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: Gilroy;
    src: url('../fonts/gilroy-thinitalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}
