.first {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // background: url('/img/globus.png') 50% 100%/cover no-repeat;
    &__in {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        box-sizing: border-box;
        padding: vw(170) vw(120) vw(120) vw(120);
        @media all and (max-width: 1023px) {
            padding: 102px 15px 40px;
            flex-direction: column;
            justify-content: space-between;
        }
    }
    &__shield {
        width: vw(540);
        max-height: 100%;
        @media all and (min-width: 1024px) {
            display: none;
            // margin-left: auto;
            pointer-events: none;
            position: relative;
            &.isInteractable {
                pointer-events: all;
            }
        }
        @media all and (max-width: 1023px) {
            order: 2;
            flex: 1 1 auto;
            width: 400px;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: flex-end;
        }
        @media all and (max-width: 400px) {
            width: 320px;
        }
        @media all and (max-width: 330px) {
            // width: 320px;
            transform: translateX(-15px);
        }
    }
    &__name {
        width: vw(500);
        color: #FFFFFF;
        padding-top: vw(100);
        @media all and (max-width: 1023px) {
            width: 100%;
            text-align: justify;
        }
        &--title {
            font-weight: 400;
            font-size: vw(32);
            line-height: vw(50);
            margin-bottom: vw(30);
            @media all and (max-width: 1023px) {
                font-size: 20px;
                line-height: 1.2;
                padding-top: 0;
                margin-bottom: 20px;
                width: 100%;
                text-align: justify;
            }
            @media all and (max-width: 400px) and (max-height: 700px) {
                margin-bottom: 0;
                font-size: 16px;
            }
            @media all and (max-width: 400px) and (max-height: 600px) {
                margin-bottom: 0;
                font-size: 16px;
                text-align: justify;
            }
        }
        &--text {
            font-weight: 400;
            font-size: vw(23);
            line-height: 140%;
            color: #FFFFFF;
            @media all and (max-width: 1023px) {
                font-size: 15px;
            }
        }
        .Typewriter__wrapper {
            color: #FFFFFF;
        }
        @media all and (min-width: 1024px) {
            display: none;
        }
        span {
            color: #21DBEF;
        }
        
    }
    &__text {
        // padding-top: vw(70);
        font-weight: 400;
        font-size: vw(23);
        line-height: 140%;
        color: #FFFFFF;
        position: absolute;
        left: 0;
        right: 0;
        align-self: center;
        transition: height 0.5s ease;
        display: none;
        @media all and (min-width: 1024px) {
            top: 0;
            bottom: 0;
            pointer-events: none;
            text-align: center;
        }
        @media all and (max-width: 1023px) {
            width: 100%;
            font-size: 16px;
            padding-top: 0;            
            box-sizing: border-box;
            margin: 0 auto;
            font-weight: normal;
            margin-bottom: 20px;
            position: absolute;
            bottom: 130px;
            height: 210px;
            pointer-events: none;
        }
        @media all and (max-width: 400px) {
            bottom: 75px;
        }
        @media all and (max-height: 650px) {
            bottom: 0 !important;
        }
    }
    &__text-in {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        opacity: 0;
        transition: opacity .3s ease;
        @media all and (min-width: 1024px) {
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
            padding: vw(150);
        }
        &.isActive {
            opacity: 1;
        }
        @media all and (max-width: 1023px) {
            width: 210px;
            height: 210px;
            margin: 0 auto;
            text-align: center;
        }
        @media all and (max-width: 400px) {
            width: 180px;
        }
    }
    &__placeholder {
        width: vw(500);
        @media all and (max-width: 1023px) {
            display: none;  
        }
    }
    .small_shield {
        cursor: pointer;
        path {
            fill: #fff;
            transition: all .3s ease;
        }
        &.isActive,
        &:hover {
            .border {
                fill: #03C4A1;
            }
        }
    }
}