.header {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 200;
    &__in {
        padding: vw(60) vw(100) 0 vw(100);
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media all and (max-width: 1023px) {
            padding: 20px 15px 0 15px;
        }
    }
    &__logo {
        display: block;
        width: vw(219);
        font-size: 0;
        @media all and (max-width: 1023px) {
            width: 88px;
        }
    }

    &__right {
        display: flex;
        align-items: center;
    }
    &__langs {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        font-size: vw(20);
        line-height: 150%;
        letter-spacing: 0.05em;
        color: #FFFFFF;
        @media all and (max-width: 1023px) {
            font-size: 14px;
        }
        li, a {
            font-weight: inherit;
            font-size: inherit;
            color: inherit;
            text-decoration: none;
        }
        li.isActive a {
            font-weight: bold;
            text-decoration: underline;
        }
        li:not(.isActive) a {
           &:hover {
               text-decoration: underline;
           } 
        }
    }
    &__login {
        margin-left: vw(60);
        @media all and (max-width: 1023px) {
            margin-left: 20px;
        }
        svg {
            fill: #fff;
        }
    }
}