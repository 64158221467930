@mixin counDelay($counter: 10) {
    @for $item from 0 through $counter {
        .section.active .nine__form > div:nth-of-type(#{$item}) {
            transition: .6s all #{($item + 2) * 1.1}s ease;
        }
        .section.active .nine__form > div:nth-of-type(#{$item}):after {
            transition: .6s all #{($item + 2) * 1.1}s ease;
        }
        .section.active .nine__form > div:nth-of-type(#{$item}) input {
            transition: .6s all #{($item + 2) * 1.1}s ease;
        }
        .section.active .nine__form > div:nth-of-type(#{$item}) input::placeholder {
            transition: .6s all #{($item + 2) * 1.1}s ease;
        }
        .section.active .nine__form > div:nth-of-type(#{$item}) input::-webkit-placeholder {
            transition: .6s all #{($item + 2) * 1.1}s ease;
        }
    }
}

.nine {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    &__in {
        width: 100%;
        height: 100%;
        padding: vw(160) vw(957) vw(0) vw(100);
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        flex-direction: column;
        box-sizing: border-box;
        @media all and (max-width: 1023px) {
            padding: 51px 15px;
        }
    }
    &__title {
        font-weight: bold;
        font-size: vw(64);
        line-height: vw(77);
        margin-bottom: vw(40);
        color: #FFFFFF;
        @media all and (max-width: 1023px) {
            font-size: 40px;
            line-height: 48px;
            margin-bottom: 25px;
        }
    }
    &__form-item {
        &:not(:first-child) {
            margin-top: vw(20);
            @media all and (max-width: 1023px) {
                margin-top: 10px;
            }
        }
        &:after {
            content: "";
            display: block;
            top: 100%;
            left: 0;
            height: 1px;
            width: 0;
            background: #0cffff;
            box-shadow: 0px 0px 5px 0px white;
            transition: all .3s ease;
        }
        input:not([type="hidden"]) {
            display: block;
            width: 100%;
            box-sizing: border-box;
            height: vw(76);
            padding: 0;
            background: transparent url('../img/svg/arrow.svg') #{vw(-31)} 50%/ #{vw(31)} no-repeat;
            border: none;
            pointer-events: none;
            // border-bottom: 2px solid #fff;
            outline: none;
            padding-right: vw(41);
            @media all and (max-width: 1023px) {
                height: 44px;
                padding-right: 41px;
                background: transparent url('../img/svg/arrow.svg') -31px 50%/ 31px no-repeat;
            }
            &,
            &::placeholder,
            &::-webkit-placeholder {
                font-size: vw(24);
                line-height: 1;
                color: transparent;
                @media all and (max-width: 1023px) {
                    font-size: 16px;
                }
            }
            // &:focus {
            //     background-position: 120% 50% !important;
            //     transition: background-position 0.5s ease-in-out !important;
            // }
        }
        
    }
    &__form-button {
        margin-top: vw(50);
        opacity: 0;
        visibility: hidden;
        @media all and (max-width: 1023px) {
            margin-top: 30px;
        }
        button {
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #03C4A1;
            border-radius: vw(5);
            padding: vw(20) vw(35);
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: background .3s ease-in-out;
            &:hover {
                background: #0ce5ec;
            }
            @media all and (max-width: 1023px) {
                border-radius: 5px;
                padding: 20px 35px;
                width: 100%;
                box-sizing: border-box;
                justify-content: space-between;
            }
            .text {
                font-size: vw(24);
                line-height: 1;
                color: #FFFFFF;
                @media all and (max-width: 1023px) {
                    font-size: 20px;
                }
            }
            .icon {
                display: block;
                width: vw(31);
                font-size: 0;
                margin-left: vw(20);
                @media all and (max-width: 1023px) {
                    width: 31px;
                    margin-left: 20px;
                }
            }
        }
    }
}

@include counDelay;
.section.active .nine__form-item:after {
    width: 100%;
}
.section.active .nine__form-item input {
    pointer-events: all;
    background-position: 100% 50%;
    &,
    &::placeholder,
    &::-webkit-placeholder {
        color: #fff;
    }
}
.section.active .nine__form-item.has-error input:not([type="hidden"]) {
    &,
    &::placeholder,
    &::-webkit-placeholder {
        color: red !important;
    }
}
.section.active .nine__form-button {
    opacity: 1;
    visibility: visible;
}

.logoOsmi {
    @media all and (max-width: 1023px) {
        display: inline-block;
        max-width: 100px;
        padding: 10px;
        box-sizing: border-box;
        img {
            max-width: 100%;
        }
    }
}