.section {
    // background: #091029;
    position: relative;
    height: 100vh;
    transition: .3s opacity .3s ease;
    opacity: 0;
    // &:nth-of-type(2) {
    //     background: red;
    // }
    // &:nth-of-type(3) {
    //     background: blue;
    // }
    &.active {
        opacity: 1;
        transition: .3s opacity 3s ease;
    }
    &.fast_show {
        transition: .3s opacity .3s ease !important;
    }
}