.to_top {
    width: vw(40);
    height: vw(40);
    position: fixed;
    z-index: 100;
    left: vw(100);
    bottom: vw(20);
    border: 2px solid #0dffff;
    border-radius: 50%;
    display: none;
    @media all and (max-width: 1023px) {
        left: auto;
        bottom: 41px;
        right: 15px;
        width: 20px;
        height: 20px;
    }
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 200;
    text-align: center;
    background: rgba(0, 0, 0, .3);
    backdrop-filter: blur(10px);
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s ease;
    &.isOpened {
        opacity: 1;
        visibility: visible;
    }
    &:after {
        content: "";
        width: 0;
        height: 100%;
        display: inline-block;
        vertical-align: middle;
    }
    &__in {
        text-align: left;
        display: inline-block;
        vertical-align: middle;
        width: 100%;
        max-width: vw(500);
        background: #000000;
        padding: vw(40);
        @media all and (max-width: 1023px) {
            padding: 20px;
            max-width: 320px;
            box-sizing: border-box;
        }
    }
    &__form-item {
        &:not(:first-child) {
            margin-top: vw(20);
            @media all and (max-width: 1023px) {
                margin-top: 10px;
            }
        }
        &:after {
            content: "";
            display: block;
            top: 100%;
            left: 0;
            height: 1px;
            width: 100%;
            background: #0cffff;
            box-shadow: 0px 0px 5px 0px white;
            transition: all .3s ease;
        }
        input:not([type="hidden"]) {
            display: block;
            width: 100%;
            box-sizing: border-box;
            height: vw(76);
            padding: 0;
            background: transparent url('../img/svg/arrow.svg') 100% 50%/ #{vw(31)} no-repeat;
            border: none;
            // border-bottom: 2px solid #fff;
            outline: none;
            
            @media all and (max-width: 1023px) {
                height: 44px;
                background: transparent url('../img/svg/arrow.svg') -31px 50%/ 31px no-repeat;
            }
            &,
            &::placeholder,
            &::-webkit-placeholder {
                font-size: vw(24);
                line-height: 1;
                color: #fff;
                @media all and (max-width: 1023px) {
                    font-size: 16px;
                }
            }
            // &:focus {
            //     background-position: 120% 50% !important;
            //     transition: background-position 0.5s ease-in-out !important;
            // }
        }
    }
    &__form-button {
        margin-top: vw(50);
        @media all and (max-width: 1023px) {
            margin-top: 30px;
        }
        button {
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #03C4A1;
            border-radius: vw(5);
            padding: vw(20) vw(35);
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: background .3s ease-in-out;
            &:hover {
                background: #0ce5ec;
            }
            @media all and (max-width: 1023px) {
                border-radius: 5px;
                padding: 20px 35px;
                width: 100%;
                box-sizing: border-box;
                justify-content: space-between;
            }
            .text {
                font-size: vw(24);
                line-height: 1;
                color: #FFFFFF;
                @media all and (max-width: 1023px) {
                    font-size: 20px;
                }
            }
            .icon {
                display: block;
                width: vw(31);
                font-size: 0;
                margin-left: vw(20);
                @media all and (max-width: 1023px) {
                    width: 31px;
                    margin-left: 20px;
                }
            }
        }
    }
}


.Typewriter__cursor {
    color: #21DBEF !important;
}