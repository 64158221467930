.second {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // background: url('/img/globus.png') 50% 100%/cover no-repeat;
    &__in {
        width: 100%;
        height: 100%;
        padding: vw(270) vw(120) vw(247) vw(120);
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @media all and (max-width: 1023px) {
            justify-content: flex-start;
            padding: 152px 15px 40px;
            align-items: center;
            text-align: center;
        }
    }
    &__text {
        font-weight: normal;
        font-size: vw(64);
        line-height: vw(77);
        margin-bottom: vw(40);
        max-width: 50%;
        color: #FFFFFF;
        @media all and (max-width: 1023px) {
            font-size: 40px;
            line-height: 48px;
            margin-bottom: 25px;
            max-width: 100%;
        }
    }
}